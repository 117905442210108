"use client";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ParentItem from "./parent-item";
import ChildItem from "./child-item";
import { generateUID } from "@/utils/functions.client";
import { useSelectShowMobileMenu } from "@/hooks";

export default function List({
  listClassName = "",
  parent = {},
  subMenu = [],
  index = 0,
  setIndex = () => {},
  currentIndex,
}) {
  const showMobileMenu = useSelectShowMobileMenu();
  const [mobileMenuActiveClass, setMobileMenuActiveClass] = useState("");
  useEffect(() => {
    if (!showMobileMenu) {
      setMobileMenuActiveClass("");
      setIndex(0);
    }
    if (currentIndex === index) {
      setMobileMenuActiveClass("active");
    } else {
      setMobileMenuActiveClass("");
    }
  }, [showMobileMenu, mobileMenuActiveClass, currentIndex, setIndex, index]);
  return (
    <li className={listClassName || ""}>
      {parent ? (
        <ParentItem
          text={parent.text}
          link={parent.link}
          mobileMenuActiveClass={mobileMenuActiveClass}
          setMobileMenuActiveClass={(e) => {
            if (showMobileMenu) {
              if (currentIndex === index) {
                setIndex(0);
              } else {
                setIndex(index);
              }
            }
          }}
        />
      ) : (
        ""
      )}
      {subMenu && subMenu.length > 0 ? (
        <ul
          className={"accordion-content " + (mobileMenuActiveClass || "")}
          style={{
            display: mobileMenuActiveClass && showMobileMenu ? "block" : "",
          }}
        >
          {subMenu.map(
            ({ text, link, linkId, linkClassName, subItems, isDisabled }) => {
              if (!isDisabled) {
                return (
                  <ChildItem
                    key={generateUID()}
                    linkId={linkId || ""}
                    text={text || ""}
                    link={link || ""}
                    linkClassName={linkClassName || ""}
                    subMenu={subItems}
                  />
                );
              }
            }
          )}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
}
List.propTypes = {
  /**
   * ClassName(s) of the parent <li> tag
   */
  listClassName: PropTypes.string,
  parent: PropTypes.shape({
    /**
     * Menu name
     */
    text: PropTypes.string.isRequired,
    /**
     * Menu link
     */
    link: PropTypes.string.isRequired,
    /**
     * Tag #id of the <a> element
     */
    linkId: PropTypes.string,
    /**
     * ClassName(s) of the <a> tag.
     */
    linkClassName: PropTypes.string,
  }).isRequired,
  /**
   * Array of object for menu details.
   */
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Menu name
       */
      text: PropTypes.string.isRequired,
      /**
       * Menu link
       */
      link: PropTypes.string.isRequired,
      /**
       * Tag #id of the <a> element
       */
      linkId: PropTypes.string,
      /**
       * ClassName(s) of the <a> tag.
       */
      linkClassName: PropTypes.string,
    })
  ).isRequired,
  index: PropTypes.number,
  /**
   * Set the index of the current menu
   */
  setIndex: PropTypes.func,
  currentIndex: PropTypes.number,
};
