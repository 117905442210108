"use client";
import Link from "next/link";
import PropTypes from "prop-types";
import "./cart-alert.css";
export default function CartAlert({ cart = {} }) {
  return (
    <div className="header__cart-alert">
      <div className="header__cart-alert--wrapper">
        <i className="fa fa-caret-up"></i>
        <div className="header__cart-alert--text">
          <p className="reqular-iq">{cart?.emptyLabel || ""}</p>
          <Link
            className="bold-iq"
            href={cart?.buy_cta_url || "/"}
            onClick={(e) => {
              if (cart?.disableBuyCta) {
                e.preventDefault();
                return;
              }
            }}
          >
            {cart?.buy_cta || ""}
          </Link>
        </div>
      </div>
    </div>
  );
}

CartAlert.propTypes = {
  /**
   * Cart info
   */
  cart: PropTypes.object.isRequired,
};
