"use client";
import PropTypes from "prop-types";
import Menu from "./menu";
import Countries from "./countries";
import { useSelectShowMobileMenu } from "@/hooks";
import './index.css';

export default function Nav({
  menus = [],
  currencyList = [],
  locales = [],
  quick_links = [],
  my_account_menus = [],
  languageCurrencyTitle = "",
}) {
  const showMobileMenu = useSelectShowMobileMenu();

  return (
    <div
      className="header__wrapper--nav desktop"
      style={{ background: "#444444" }}
    >
      <div className="container">
        {showMobileMenu && <div className="mobile_overlay"></div>}
        <div className={"header__nav " + (showMobileMenu ? "active" : "")}>
          <div className="header__nav--wrapper">
            <nav>
              <Menu
                menus={menus || []}
                quick_links={quick_links || []}
                my_account_menus={my_account_menus || []}
                languageCurrencyTitle={languageCurrencyTitle || ""}
              />
              <ul className="header__nav--right">
               
                <Countries locales={locales || []} />
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

Nav.propTypes = {
  /**
   * Menu items
   */
  menus: PropTypes.array.isRequired,
  /**
   * Currencies
   */
  currencyList: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Record id
       */
      id: PropTypes.number.isRequired,
      /**
       * Currency ISO code
       */
      currencyId: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  /**
   * Languages
   */
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Country code
       */
      code: PropTypes.string.isRequired,
      /**
       * Language name
       */
      name: PropTypes.string.isRequired,
      /**
       * Image
       */
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Quick links
   */
  quick_links: PropTypes.array.isRequired,
  /**
   * Language and Currency title
   */
  languageCurrencyTitle: PropTypes.string.isRequired,
};
