"use client";
import { useState } from "react";
import PropTypes from "prop-types";
import { ListLink } from "@/utils/components.client";
import List from "./list";
import {
  useSelectShowMobileMenu,
  useDispatchSetShowMobileMenu,
  useSelectAuthUser,
  useDispatchLogout,
  useDispatchShowLoader,
  useSelectLocale,
  useRedirect,
  useSelectCsrfToken,
} from "@/hooks";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import "@/app/[lang]/styles/style.css";
import { useSelector } from "react-redux";
import { trackClickEvent } from "@/utils/gtm";
import "./index.css";

export default function Menu({
  menus = [],
  quick_links = [],
  my_account_menus = [],
  languageCurrencyTitle = "",
}) {
  const authUser = useSelectAuthUser();
  const showMobileMenu = useSelectShowMobileMenu();
  const locale = useSelectLocale();
  const csrfToken = useSelectCsrfToken();
  const dispatchSetShowMobileMenu = useDispatchSetShowMobileMenu();
  const dispatchLogout = useDispatchLogout();
  const dispatchShowLoader = useDispatchShowLoader();
  const redirect = useRedirect();
  const [currentIndex, setCurrentIndex] = useState(0);
  const gtmData = useSelector((state) => state?.authUser?.data);
  const menuNavs = [];
  menus?.length &&
    menus?.length > 0 &&
    menus?.map(
      ({
        attributes: { text, link, listClassName, menuItems, slug, isDisabled },
        id,
      }) => {
        if (!isDisabled) {
          if (Array.isArray(menuItems) && menuItems?.length > 0) {
            menuNavs.push(
              <List
                key={id + slug}
                listClassName={listClassName || ""}
                parent={{ text: text, link: link }}
                subMenu={menuItems}
                index={id}
                setIndex={setCurrentIndex}
                currentIndex={currentIndex}
              />
            );
          } else {
            menuNavs.push(
              <ListLink
                key={id + slug}
                listClassName={listClassName || ""}
                text={text || ""}
                link={link || "/#link-here"}
                showMobileMenu={showMobileMenu}
                color={!showMobileMenu && "white"}
                onClickExtension={() => {
                  if (showMobileMenu) dispatchSetShowMobileMenu(false);
                }}
              />
            );
          }
        }
      }
    );

  if (authUser?.profile?.dateOfBirth && showMobileMenu) {
    const loggedin_menus = [];
    my_account_menus?.length &&
      my_account_menus?.length > 0 &&
      my_account_menus?.map(
        ({
          attributes: {
            menu: { title, link, name, tabs, slug, isDisabled },
          },
          id,
        }) => {
          if (!isDisabled) {
            const childMenus = [];
            if (Array.isArray(tabs) && tabs?.length > 0) {
              tabs?.map(({ name, link, slug, isDisabled }) => {
                if (!isDisabled) {
                  childMenus.push({
                    text: name,
                    link: link,
                    linkId: "",
                    linkClassName: "",
                    subItems: [],
                  });
                }
              });
            }
            loggedin_menus.push({
              text: name,
              link,
              linkId: "",
              linkClassName: "",
              subItems: childMenus,
            });
          }
        }
      );

    menuNavs.push(
      <List
        key="my-account-parent-menu"
        listClassName={"accordion-nested"}
        parent={{
          text: locale === "ar" ? "حسابي" : "My Account",
          link: "#",
        }}
        subMenu={loggedin_menus}
        index={menuNavs.length}
        setIndex={setCurrentIndex}
        currentIndex={currentIndex}
      />
    );
  }
  return (
    <>
      {showMobileMenu && authUser?.profile?.firstName ? (
        <>
          <h6 className="bold-iq username mb-0">
            {(locale === "ar" ? "أهلاً," : "Hi,") +
              " " +
              (authUser?.profile?.firstName || "User")}
          </h6>
          <br />
        </>
      ) : (
        ""
      )}
      <ul className="header__nav--menu">
        <ListLink
          listClassName="header__login-menu--button primary hide-on-desktop border-top"
          linkId="buy_now_mobile_highlighted"
          text={quick_links?.[0]?.attributes?.link?.text || ""}
          link={quick_links?.[0]?.attributes?.link?.link || "#"}
          onClickExtension={(e) => {
            if (quick_links?.[0]?.attributes?.link?.isDisabled) {
              e.preventDefault();
              return;
            }
            if (showMobileMenu) dispatchSetShowMobileMenu(false);
          }}
        />
        {menuNavs?.length && menuNavs.length > 0 ? menuNavs : ""}
        {!authUser?.profile?.dateOfBirth ? (
          <>
            <ListLink
              listClassName="header__login-menu--button primary hide-on-desktop"
              text={quick_links?.[1]?.attributes?.link?.text || ""}
              link={quick_links?.[1]?.attributes?.link?.link || "#"}
              color="black"
              onClickExtension={(e) => {
                if (quick_links?.[1]?.attributes?.link?.isDisabled) {
                  e.preventDefault();
                  return;
                }
                if (showMobileMenu) dispatchSetShowMobileMenu(false);
              }}
            />
            <ListLink
              listClassName="header__login-menu--button primary hide-on-desktop"
              text={quick_links?.[2]?.attributes?.link?.text || ""}
              link={quick_links?.[2]?.attributes?.link?.link || "#"}
              color="black"
              onClickExtension={(e) => {
                if (quick_links?.[2]?.attributes?.link?.isDisabled) {
                  e.preventDefault();
                  return;
                }

                if (showMobileMenu) dispatchSetShowMobileMenu(false);
              }}
            />
          </>
        ) : (
          <ListLink
            listClassName="header__login-menu--button hide-on-desktop"
            text={locale === "ar" ? "تسجيل الخروج" : "Sign Out"}
            link="#"
            onClickExtension={async (e) => {
              e.preventDefault();
              if (showMobileMenu) dispatchSetShowMobileMenu(false);
              dispatchShowLoader(true);
              dispatchLogout();
              const logout = await sendInternalPostRequest({
                endpoint: "/api/logout",
                csrfToken,
              });
              if (logout?.success) {
                redirect("/" + locale + "/login");
              }
              dispatchShowLoader(false);
              trackClickEvent(
                "e_headerinteraction",
                "non_ecommerce",
                "logout",
                "",
                gtmData?.lang || "en",
                gtmData?.currentPage || "",
                gtmData?.category || "",
                gtmData?.subcategory || "",
                gtmData?.previousPage || "",
                gtmData?.loggedIn || "",
                gtmData?.userid || ""
              );
            }}
          />
        )}
      </ul>
    </>
  );
}

Menu.protoTypes = {
  /**
   * Menu lists
   */
  menus: PropTypes.array.isRequired,
  /**
   * Quick links
   */
  quick_links: PropTypes.array.isRequired,
  /**
   * Language and Currency title
   */
  languageCurrencyTitle: PropTypes.string.isRequired,
};
