"use client";
import axios from "axios";
import PropTypes from "prop-types";
import {
  INTERNAL_SERVER_ERROR,
  INVALID_REQUEST_ERROR,
} from "@/utils/error-constants";

export default async function sendInternalPostRequest({
  csrfToken = "",
  endpoint = "",
  data = {},
  contentType = "application/json",
}) {
  let response = null;

  try {
    if (endpoint && csrfToken) {
      response = await axios
        .post(endpoint, data, {
          headers: {
            "Content-Type": contentType,
            "X-CSRF-Token": csrfToken || "",
          },
        })
        .then((res) => res?.data || null)
        .catch((error) => {
          // if (error?.response?.status >= 400) {
          //   throw new Error(INVALID_REQUEST_ERROR);
          // }
          if (error?.response?.status >= 500) {
            throw new Error(INTERNAL_SERVER_ERROR);
          }
          return error?.response?.data || null;
        });
    } else {
      throw new Error(INVALID_REQUEST_ERROR);
    }
  } catch (error) {
    response = null;
    console.log("InternalPostRequest => " + error);
    throw new Error(error);
  }

  return response;
}

sendInternalPostRequest.propTypes = {
  /**
   * CSRF token
   */
  csrfToken: PropTypes.string.isRequired,
  /**
   * Endpoint
   */
  endpoint: PropTypes.string.isRequired,
  /**
   * Data
   */
  data: PropTypes.object,
  /**
   * Content type
   */
  contentType: PropTypes.string,
};
